import React from "react";
import { CtaLink } from "../../Elements";
import { Wrapper, Pipe, Links } from "./style";

const LinksList = ({ navigationList, isPipeSeparated, fontColor }) => {
	const renderListitem = () => {
		const n = navigationList.length - 1;
		return navigationList.map(
			({ link: { link, type, target }, name }, index) => {
				return (
					<li key={`${name}-${index}`}>
						<CtaLink
							Link={link}
							type={type}
							value={link}
							target={target}
							className="link"
						>
							{name}
						</CtaLink>
						{isPipeSeparated && index !== n && (
							<Pipe fontColor={fontColor}></Pipe>
						)}
					</li>
				);
			},
		);
	};

	return (
		<Wrapper>
			<Links isPipeSeparated={isPipeSeparated}>{renderListitem()}</Links>
		</Wrapper>
	);
};

export default LinksList;
