import styled from "styled-components";
import { COLOR_WHITE, FONT_WT_BOLD } from "@website-builder/utilities-index";

export const Wrapper = styled.section`
	display: flex;
	justify-content: center;
`;

export const Links = styled.ul`
	list-style: none;
	padding: 0 10px;
	${({ isPipeSeparated }) => {
		if (isPipeSeparated) {
			return `
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      `;
		}
	}}
	li {
		padding: 5px;
	}
	a,
	a:visited,
	a:active,
	a:hover {
		font-weight: ${FONT_WT_BOLD};
		color: inherit;
		padding: 5px;
	}
`;

export const Pipe = styled.span`
	border-left: 1px solid ${({ fontColor = COLOR_WHITE }) => fontColor};
	height: 40px;
	margin: 5px;
`;
